import { IS_CLIENT } from '../constants';

export const normalizeParams = ({ search }) => {
  if (!search) {
    return {};
  }

  const sanitized = search.substr(1);
  const paramsArr = sanitized.split('&');

  return paramsArr.reduce((acc, curr) => {
    const param = curr.split('=');
    const [key, value] = param;
    acc[key] = value;
    return acc;
  }, {});
};

export const getQueryParams = (params) => {
  if (!IS_CLIENT) {
    return [];
  }

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return params.map((param) => urlParams.get(param));
};
